import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { IError } from '~/repository/extensions/error/error.types';
import type { IInventory } from '~/repository/modules/quickSale/QuickSaleService.types';
import { useAlertStore } from '~/store/alert/alert.store';
import { useUserStore } from '~/store/user/user.store';

export const useQsInventory = defineStore('qs/inventory', () => {
  const {
    $api,
    $i18n: { t },
    $projectConfig: {
      redirects: { newCases },
    },
  } = useNuxtApp();
  const alertStore = useAlertStore();
  const userStore = useUserStore();

  // количество элементов на странице, в запросе с пагинацией
  const TOTAL_ITEMS_ON_PAGE = 45;

  // текущая страница, по которой получен инвентарь
  const currentPage = ref(0);
  // всего страниц
  const lastPage = ref(1);

  // инвентарь
  // общее количество айтемов, получаем с бэка
  const totalItems = ref(0);
  // общая стоимость айтемов, с бэка
  const totalPrice = ref('0');
  // весь инвентарь, подгруженный на данный момент
  const allInventory = ref<IInventory[]>([]);
  // инвентарь, получаемый с одной страницы по запросу
  const inventorySinglePage = ref<IInventory[]>([]);
  // состояние запроса
  const isPending = ref(false);
  const isSellInventoryLoading = ref(false);

  // получение инвентаря по одной странице (вспомогательный)
  const getInventory = async (page: number) => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        const data = await $api.quickSale.getInventory(page, TOTAL_ITEMS_ON_PAGE);
        inventorySinglePage.value = data.items;
        lastPage.value = data.lastPage;
        if (currentPage.value === 1) {
          totalItems.value = data.totalItem;
          totalPrice.value = (+data.totalPrice).toFixed(2);
        }
      },
      (e: IError) => informOnError(e),
      isPending,
    );
  };

  // логика получения инвентаря с пагинацией
  const fetchInventory = async () => {
    if (!isPending.value && currentPage.value < lastPage.value) {
      try {
        currentPage.value++;
        await getInventory(currentPage.value);
        allInventory.value = [...allInventory.value, ...inventorySinglePage.value];
      } catch (e: unknown) {
        inventorySinglePage.value = [];
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  // сброс инвентаря
  const resetInventory = () => {
    allInventory.value = [];
    currentPage.value = 0;
  };

  // продажа
  // продажа одного предмета из инвентаря, с обновлением профиля
  const sellItemFromInventory = async (id: number | string) => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        await $api.inventory.sellDrop(String(id));
        await userStore.getMe();

        alertStore.show({
          title: t('alerts.itemInSaleQueue'),
          type: 'success',
        });
      },
      (error: IError) => {
        alertStore.showError({
          message: (error as IError).msg,
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
      isSellInventoryLoading,
    );
  };

  // продажа выбранных предметов в поп-апе инвентаря
  const sellInventory = async () => {
    const idsInventory = selectedInventory.value.map((item) => item.id);
    await GlobalUtils.Api.handleRequest(
      async () => {
        await $api.quickSale.sellManyInventory(idsInventory);
        // делаю подгрузку, если весь видимый инвентарь выбрали и нажали продать (не продать все!)
        if (quantitySelectedItems.value === allInventory.value.length) {
          await fetchInventory();
        }

        // подкручиваю значения для корректного отображения в счетчиках в футере попапа
        totalItems.value = totalItems.value - quantitySelectedItems.value;
        totalPrice.value = String(+totalPrice.value - totalSumSelectedItems.value);
        allInventory.value = allInventory.value.filter((el) => !selectedInventory.value.includes(el));

        alertStore.show({
          title: t('alerts.allItemsInSaleQueue'),
          type: 'success',
        });
      },
      (e: IError) => informOnError(e),
      isSellInventoryLoading,
    );
  };

  // продажи всего инвентаря ( с обновлением профиля )
  const sellAllInventory = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        await $api.inventory.sellAllItems();

        alertStore.show({
          title: t('alerts.allItemsInSaleQueue'),
          type: 'success',
        });
        resetInventory();
      },
      (error: IError) => {
        alertStore.showError({
          message: (error as IError).msg,
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
      isSellInventoryLoading,
    );
  };

  // выбрать
  const selectedItem = (id: number) => {
    const item = allInventory.value.find((el) => el.id === id);
    if (item) item.isSelected = true;
  };
  // выбрать все
  const selectedAllItem = () => {
    allInventory.value.forEach((el) => {
      el.isSelected = true;
    });
  };
  // сбросить
  const removeItemFromSelected = (id: number) => {
    const item = allInventory.value.find((el) => el.id === id);
    if (item) item.isSelected = false;
  };
  // сбросить все
  const removeAllFromSelected = () => {
    allInventory.value.forEach((el) => {
      el.isSelected = false;
    });
  };

  const redirectToOpenCase = () => {
    const localeRoute = useLocaleRoute();
    return navigateTo(localeRoute(newCases));
  };

  // геттеры
  // выбранный инвентарь
  const selectedInventory = computed<IInventory[]>(() => {
    return allInventory.value.filter((el) => el.isSelected);
  });
  // количество выбранных предметов
  const quantitySelectedItems = computed(() => selectedInventory.value.length);

  // общая сумма выбранных предметов
  const totalSumSelectedItems = computed(() => selectedInventory.value.reduce((acc, value) => acc + +value.price, 0));

  return {
    allInventory,
    fetchInventory,
    isPending,
    isSellInventoryLoading,
    quantitySelectedItems,
    redirectToOpenCase,
    removeAllFromSelected,
    removeItemFromSelected,
    resetInventory,
    selectedAllItem,
    selectedInventory,
    selectedItem,
    sellAllInventory,
    sellInventory,
    sellItemFromInventory,
    totalItems,
    totalPrice,
    totalSumSelectedItems,
  };
});
