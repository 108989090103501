<template>
  <footer class="footer-controller">
    <div class="footer-controller__container">
      <div class="footer-controller__information">
        <div class="footer-controller__information-about">
          <component :is="logo" key="footer-logo" class="footer-controller__information-about--logo" />
          <div class="footer-controller__information-about--text">
            <p>{{ copyright }}</p>
            <p>{{ beneficiary }}. {{ address }}</p>
          </div>
        </div>
        <div v-if="!hideSocials" class="footer-controller__information-socials">
          <FooterControllerSocialIcon
            v-for="social in currentVariant.socials"
            :key="social.variant"
            :variant="social.variant"
            :count="social.count"
          />
        </div>
      </div>
      <div class="footer-controller__links">
        <NuxtLinkLocale v-for="item in links" :key="item.name" class="footer-controller__links-item" :to="item.href">
          {{ item.name }}
        </NuxtLinkLocale>
      </div>
      <div v-if="!hidePaymentMethods" class="footer-controller__methods">
        <SvgoFooterVisa class="footer-controller__methods-visa" />
        <SvgoFooterMastercard class="footer-controller__methods-mastercard" />
        <SvgoFooterQiwi class="footer-controller__methods-qiwi" />
        <SvgoFooterYoomoney class="footer-controller__methods-yoomoney" />
        <SvgoFooterWebmoney class="footer-controller__methods-webmoney" />
        <SvgoFooterHotskins class="footer-controller__methods-hotskins" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { EFooterProjects, type IFooterProps } from './FooterController.types';
import { ESocialIconVariant } from './SocialIcon/SocialIcon.types';

const {
  $projectConfig: {
    footer: { logo, copyright: copyrightName, hidePaymentMethods, hideSocials },
    legalData: { beneficiary, address },
  },
} = useNuxtApp();

const { t } = useI18n();

const CURRENT_YEAR = new Date().getFullYear();

const props = withDefaults(defineProps<IFooterProps>(), {
  variant: EFooterProjects.DOTA,
});
const { variant } = toRefs(props);

const copyright = computed(() => t('footer.copyright', { company: copyrightName, year: CURRENT_YEAR }));

const variants = {
  [EFooterProjects.DOTA]: {
    socials: [
      {
        count: 110000,
        variant: ESocialIconVariant.VK,
      },
      {
        count: 17463,
        variant: ESocialIconVariant.TELEGRAM,
      },
      {
        count: 8986,
        variant: ESocialIconVariant.INSTAGRAM,
      },
    ],
  },
  [EFooterProjects.CS]: {
    socials: [
      {
        count: 100762,
        variant: ESocialIconVariant.VK,
      },
      {
        count: 12285,
        variant: ESocialIconVariant.TELEGRAM,
      },
      {
        count: 8253,
        variant: ESocialIconVariant.INSTAGRAM,
      },
    ],
  },
};

const links = computed(() => {
  return [
    {
      href: ROUTING.CONTACTS.MAIN,
      name: t('footer.links.about'),
    },
    {
      href: ROUTING.USERAGREEMENT.MAIN,
      name: t('footer.links.agreement'),
    },
    {
      href: ROUTING.EVENTCONDITION.MAIN,
      name: t('footer.links.rules'),
    },
    {
      href: ROUTING.COOKIES.MAIN,
      name: t('footer.links.policy'),
    },
    {
      href: ROUTING.FAQ.MAIN,
      name: t('footer.links.faq'),
    },
  ];
});

const currentVariant = computed(() => variants[variant.value]);
</script>

<style scoped lang="scss" src="./FooterController.scss" />
