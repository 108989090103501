<template>
  <div class="sale-all-skin-container">
    <SharedKitButton
      :is-disabled="isDropSold || disabled"
      :color-preset="ColorPresets.DOTA.ACCENT"
      :size-preset="actionButtonsSize"
      :type-preset="TypePresets.RECTANGLE"
      class="summary-container__button"
      :text="buttonText"
      @click.once="handleSellAllDrop"
    >
    </SharedKitButton>
  </div>
</template>

<script setup lang="ts">
import type { ISaleAllSkinButtonEmits, ISaleAllSkinButtonProps } from '../SaleAllSkinButton/SaleAllSkinButton.types';
import { SizePresets, ColorPresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

const { t } = useI18n();

const props = withDefaults(defineProps<ISaleAllSkinButtonProps>(), {
  disabled: false,
  sum: 0,
});
const emit = defineEmits<ISaleAllSkinButtonEmits>();

const isDropSold = ref(false);

const handleSellAllDrop = () => {
  isDropSold.value = true;
  emit('sell-all');
};

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const actionButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));

const buttonText = computed(() =>
  t('battles.creationUpperFilter.sellAllSkinsFor', {
    inventorySum: GlobalUtils.Currency.formatCurrency(props.sum ? Number(props.sum).toFixed(2) : 0),
  }),
);
</script>

<style src="./SaleAllSkinButton.scss" scoped lang="scss"></style>
