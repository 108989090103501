<template>
  <div class="content">
    <div class="content-top">
      <SharedText v-bind="changeXL(titleStyleDesktop, titleStyle)" class="content__title">{{
        $t('quickSale.sellItems')
      }}</SharedText>
      <div class="content-top__buttons">
        <div class="content-top__buttons-group">
          <BattlesButtonsGroup :border-radius="26" :direction="EButtonsGroupDirection.ROW" :gap="2">
            <SharedKitButton
              :color-preset="ColorPresets.DOTA.QUICKSALE"
              :size-preset="actionButtonsSize"
              :type-preset="TypePresets.NONE"
              :text="$t('quickSale.selectAll')"
              class="content-top__btn"
              :is-disabled="isEmptyInventory"
              @click="emit('selected-all')"
            />
            <SharedKitButton
              :color-preset="ColorPresets.DOTA.QUICKSALE"
              :size-preset="actionButtonsSize"
              :type-preset="TypePresets.NONE"
              :text="$t('quickSale.clearAll')"
              class="content-top__btn"
              :is-disabled="isEmptyInventory"
              @click="emit('cleared-all')"
            />
          </BattlesButtonsGroup>
        </div>
      </div>
    </div>
    <div ref="contentInventory" :class="inventoryClasses">
      <NotActiveItems v-if="isEmptyInventory" />

      <div class="content__loader">
        <CaseLoader v-if="isPending && !allInventory.length" />
      </div>

      <transition-group name="list-item">
        <template v-if="!isEmptyInventory">
          <QsDropItem
            v-for="(dropItem, idx) in allInventory"
            :key="idx"
            :inventory="dropItem"
            @selected-toggle="(id) => emit('selected-toggle', id)"
          />
          <div ref="inventoryLast"></div>
        </template>
      </transition-group>
    </div>
    <div v-if="isEmptyInventory || isFirstPending" class="content-bottom content-bottom__empty">
      <SharedKitButton
        v-if="!isPending"
        :color-preset="ColorPresets.DOTA.PRIMARY"
        :size-preset="emptyButtonsSize"
        :type-preset="TypePresets.NONE"
        :text="$t('mainPage.getCasesMethods.method1Button')"
        class="empty__btn"
        @click="emit('redirect-to-open')"
      />
    </div>
    <div v-else-if="!isPending || allInventory.length" class="content-bottom">
      <div class="pagination__loader">
        <CaseLoader v-if="isPending && allInventory.length" />
      </div>
      <SaleSummary
        :selected-count="quantitySelectedItems"
        :sale-sum="totalSumSelectedItems"
        :all-count="totalItems"
        :is-button-disabled="isPendingSell || !quantitySelectedItems"
        @sell="handleSell"
      />
      <SaleAllSkinButton :disabled="isPendingSell" :sum="totalSumItems" @sell-all="handleSellAll" />
    </div>
  </div>
</template>

<script setup lang="ts">
import QsDropItem from '../QsDropItem/QsDropItem.vue';
import NotActiveItems from '../NotActiveItems/NotActiveItems.vue';
import SaleSummary from '../SaleSummary/SaleSummary.vue';
import SaleAllSkinButton from '../SaleAllSkinButton/SaleAllSkinButton.vue';
import type { IQuickSaleContent, IQuickSaleContentEmits } from './QuickSaleContent.types';
import { titleStyle, titleStyleDesktop, colorStyle } from './QuickSaleContent.data';
import CaseLoader from '~/features/cases/components/CaseLoader/CaseLoader.vue';
import { EButtonsGroupDirection } from '~/features/battles/components/ButtonsGroup/ButtonsGroup.types';
import { SizePresets, ColorPresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = defineProps<IQuickSaleContent>();

const emit = defineEmits<IQuickSaleContentEmits>();

const { allInventory, isPending, totalItems, quantitySelectedItems, totalSumSelectedItems, totalSumItems } =
  toRefs(props);

const actionButtonsSize = computed(() => changeXL(SizePresets.M, SizePresets.S));
const emptyButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));

const isEmptyInventory = computed(() => !allInventory.value.length && !isPending.value);
const isFirstPending = computed(() => isPending.value && !allInventory.value.length);

const handleSell = () => {
  emit('sell-selected-items');
};

const handleSellAll = () => {
  emit('sell-all-inventory');
};

const inventoryClasses = computed(() => ['content-inventory', { 'content-inventory__empty': isEmptyInventory.value }]);

const inventoryLast = ref<HTMLElement | undefined>();
const contentInventory = ref<HTMLElement | undefined>();

const observer = new IntersectionObserver((data: IntersectionObserverEntry[]) => {
  if (data[0].isIntersecting) emit('fetchItem');
});

watch(inventoryLast, () => {
  if (!inventoryLast.value) return;
  observer.observe(inventoryLast.value);
});
</script>
<style src="./QuickSaleContent.scss" scoped lang="scss"></style>

<style scoped lang="scss">
.content {
  --bg-color: v-bind(colorStyle.bgColor);
  --bg-color-inventory: v-bind(colorStyle.inventoryBgColor);
  --scroll-color: v-bind(colorStyle.scrollColor);
}
</style>
