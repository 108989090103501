<template>
  <SharedModal v-model="isOpen" class="qs-modal">
    <QuickSaleContent
      :all-inventory="allInventory"
      :is-pending="isPending"
      :is-pending-sell="isSellInventoryLoading"
      :total-items="totalItems"
      :quantity-selected-items="quantitySelectedItems"
      :total-sum-selected-items="totalSumSelectedItems"
      :total-sum-items="totalPrice"
      @redirect-to-open="handlerRedirect"
      @selected-toggle="(id) => handlerSelectedInventory(id)"
      @selected-all="handlerSelectedAllItem"
      @sell-selected-items="handlerSellInventory"
      @cleared-all="handlerRemoveAllFromSelected"
      @fetch-item="qsInventory.fetchInventory"
      @sell-all-inventory="handlerSellAllInventory"
    />
  </SharedModal>
</template>

<script setup lang="ts">
import QuickSaleContent from '../../components/QuickSaleContent/QuickSaleContent.vue';
import { useQsInventory } from '../../store/qs.inventory';
import type { IQuickSaleControllerEmits, IQuickSaleControllerProps } from './QuickSaleController.types';
import { QuickSaleEvents } from '~/repository/amplitude/events/quickSale';

const props = defineProps<IQuickSaleControllerProps>();
const emits = defineEmits<IQuickSaleControllerEmits>();
const qsInventory = useQsInventory();

const {
  allInventory,
  isPending,
  selectedInventory,
  totalItems,
  quantitySelectedItems,
  totalSumSelectedItems,
  totalPrice,
  isSellInventoryLoading,
} = storeToRefs(qsInventory);

const isOpen = computed<boolean>({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emits('update:modelValue', newValue);
  },
});

watch(isOpen, (value) => {
  value ? qsInventory.fetchInventory() : qsInventory.resetInventory();
});

const handlerSelectedInventory = (id: number) => {
  selectedInventory.value.find((el) => el.id === id)
    ? qsInventory.removeItemFromSelected(id)
    : qsInventory.selectedItem(id);
};

const handlerRedirect = () => {
  isOpen.value = false;

  QuickSaleEvents.toCasesClicked();

  qsInventory.redirectToOpenCase();
};

const handlerSellInventory = () => {
  qsInventory.sellInventory();

  QuickSaleEvents.itemsSold({ 'Item Count': selectedInventory.value.length, 'Item Sum': totalSumSelectedItems.value });
};

const handlerSellAllInventory = () => {
  qsInventory.sellAllInventory();

  QuickSaleEvents.allItemsSold({ 'Item Count': totalItems.value, 'Item Sum': +totalPrice.value });
};

const handlerRemoveAllFromSelected = () => {
  qsInventory.removeAllFromSelected();
  const countNumber = qsInventory.allInventory;

  QuickSaleEvents.resetAllClicked({ 'Item Count': countNumber.length });
};

const handlerSelectedAllItem = () => {
  qsInventory.selectedAllItem();
  const countNumber = qsInventory.allInventory;

  QuickSaleEvents.chooseAllClicked({ 'Item Count': countNumber.length });
};
</script>
