<template>
  <div class="summary-container">
    <SharedKitButton
      :is-disabled="isButtonDisabled"
      :color-preset="ColorPresets.DOTA.PRIMARY"
      :size-preset="actionButtonsSize"
      :type-preset="TypePresets.RECTANGLE"
      :text="$t('sell')"
      class="summary-container__button"
      @click="emit('sell')"
    />

    <div class="summary-container__text-wrapper">
      <SharedText class="summary-container__text-wrapper__field" :weight="500" :size="buttonFontSize">
        {{ $t('quickSale.selected') }}
        <span class="summary-container__text-wrapper__field__span">
          {{ `${selectedCount}/${allCount}` }}
        </span>
      </SharedText>
      <SharedText class="summary-container__text-wrapper__field" :weight="500" :size="buttonFontSize">
        {{ $t('quickSale.for') }}
        <span class="summary-container__text-wrapper__field__span">
          {{ GlobalUtils.Currency.formatCurrency(saleSum ? saleSum.toFixed(2) : 0) }}
        </span>
      </SharedText>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISaleSummaryProps, ISaleSummaryEmits } from './SaleSummary.types';

import { SizePresets, ColorPresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

const props = withDefaults(defineProps<ISaleSummaryProps>(), {
  allCount: 0,
  saleSum: 0,
  selectedCount: 0,
});

const emit = defineEmits<ISaleSummaryEmits>();

const { selectedCount, saleSum } = toRefs(props);

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const buttonFontSize = computed(() => changeXL(14, 12));
const actionButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));
</script>

<style scoped src="./SaleSummary.scss" lang="scss"></style>
